import { Link } from "react-router-dom";
import style from "./stylepage/Home.module.css";
import gamification_img from "./../source/gamification.png";
import diffusione_img from "./../source/diffusione.png";
import engagment_img from "./../source/engagment.png";


function Home() {
    //let [error, setError] = useState<{[name: string]: string|null}>({});

    return (
        <>
           <div className={style.hero}>
                <p className={style["big-text"]}>
                    Il Marketing che diverte
                </p>
                <p className={style["text"]}>
                    Aumenta il <span className="bold c_normal">coinvolgimento</span> dei tuoi clienti 
                    e acquisiscine di nuovi, con AdverPlay riuscirai 
                    a creare il game event perfetto per i tuoi <span className="bold c_normal">obiettivi</span>
                </p>
                <a className="btn" title="contatta AdverPlay via email" href="mailto:gadmaniaservice@outlook.it?subject=Richiesta di contatto">
                    Conttataci
                </a>
           </div>

           <div className={style["icon-box"]}>
                <div className={style.icon}>
                    <img alt="gamification icon" src={gamification_img}/>
                    <p>Gamification</p>
                </div>
                <div className={style.icon}>
                    <img alt="diffusione icon" src={diffusione_img}/>
                    <p>Diffusione</p>
                </div>
                <div className={style.icon}>
                    <img alt="engagment icon" src={engagment_img}/>
                    <p>Engagment</p>
                </div>

           </div>

            <div className={style["info-box"]}>
                <div>
                    <p>Cosa facciamo?</p>
                    <p>
                        Creiamo il <span className="bold c_normal">videogioco</span> perfetto per la tua attività, 
                        capace di aumentare il coinvolgimento del tuo pubblico 
                        distinguendoti dalla concorrenza.
                    </p>
                </div>
                <div className={style["event-box"]}>
                    <Link className="btn" to="wheel">Guarda i nostri eventi</Link>
                </div>
           </div>
        </>
    );
  }
  
  export default Home;