import { Schema } from "mongoose";
import { CheckWheelCode } from "./WheelCode.model";

export class Aword {
    name: string;
    qt: number;
}


export type WheelCustomPage = {
    bg: string;
    text: string;

    btnBg: string;
    btnT: string;
    btnB: string;

    inputBg: string;
    inputT: string;
    inputB: string;

    labelT: string;

    listHBg: string;
    listFBg: string;
    listSBg: string;
    listHT: string;
    listFT: string;
    listST: string;

    wheelBg: string[];
    wheelTC: string[];
    wheelFF: string;
    wheelFS: number;
    wheelOBW: number;
    wheelOBC: string;
    wheelRLW: number;
    wheelRLC: string;
    wheelSpeen: number;
    wheelBS: {width: number, color:string}
    wheelImgWhite: boolean; 
}


export type WheelEventModel ={
    _id: Schema.Types.ObjectId;
    name: string;
    description: string;
    end:Date;
    runGame: number;
    runMax: number;
    staff: string[];
    master: string[];
    awards:  Aword[];
    cPage: WheelCustomPage;
    codes: CheckWheelCode[];
}


export class WheelEventFetchUrl{

    public static GetWheelEvent(name:string){
        return `wheelevent/${name}`;
    }

    public static GetAllWheelEvents(){
        return `wheelevent`;
    }

} 