import React, { useContext, useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { Wheel } from 'paramall-wheel-of-fortune'
import { WheelData } from "paramall-wheel-of-fortune/dist/components/Wheel/types";

import CustomServices from "./../services/services";
import { LoginContext, PopUpContext } from "./../store/context";
import WheelCodeGenerator from "./../component/staff/WheelCodeGenerator";

import style from "./stylepage/Wheelevent.module.css"
import Form from "./../component/form/Form";
import Input from "./../component/form/Input";
import { WheelEventFetchUrl, WheelEventModel } from "./../model/WheelEvent.model";
import { UseWheelCodeDto, WheelCodeFetchUrl } from "./../model/WheelCode.model";
import WheelCodeCheck from "./../component/staff/WheelCodeCheck";

import Imgtriangle from "./../source/triangle.png";


function WheelEvent() {
  let [user , setUser] = useContext(LoginContext);  
  let wheelEvent = useLoaderData() as WheelEventModel;
  const cPage = wheelEvent.cPage;
  const root = document.documentElement;

  const code = useState<string>("");
  const error = useState<{[name: string]: string|null}>({});

  const [popUp, setPopUp] = useContext(PopUpContext);

  const [isRotating, setIsRotating] = useState(false);
  const [isInPending, setIsInPending] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [prize, setPrize] = useState<string>("riprova");
  const [awards, setAwards] = useState<WheelData[]>([]);

  const isStaff = user?.id &&  wheelEvent.staff.includes(user.id);
  const isMaster = user?.id &&  wheelEvent.master.includes(user.id);


  useEffect(() => {
    root.style.setProperty("--wheel_bg", cPage?.bg ?? "#f7f7f8" );
    root.style.setProperty("--wheel_text", cPage?.text ?? "#343434" );

    root.style.setProperty("--wheel_btn_bg", cPage?.btnBg ?? "#343434");
    root.style.setProperty("--wheel_btn_text",cPage?.btnT ?? "#f7f7f8" );
    root.style.setProperty("--wheel_btn_border", cPage?.btnB ?? "2px solid #343434" );

    root.style.setProperty("--wheel_input_bg", cPage?.inputBg ?? "#cee5f2" );
    root.style.setProperty("--wheel_input_text", cPage?.inputT ?? "#343434" );
    root.style.setProperty("--wheel_input_border", cPage?.inputB ?? "2px solid #cee5f2" );

    root.style.setProperty("--wheel_label_text", cPage?.labelT ?? "#343434" );

    root.style.setProperty("--wheel_list_hbg", cPage?.listHBg ?? "#637081" );
    root.style.setProperty("--wheel_list_htext", cPage?.listHT ?? "#f7f7f8" );
    root.style.setProperty("--wheel_list_fbg", cPage?.listFBg ?? "#f7f7f8" );
    root.style.setProperty("--wheel_list_ftext", cPage?.listFT ?? "#343434" );
    root.style.setProperty("--wheel_list_sbg", cPage?.listSBg ?? "#cee5f2" );
    root.style.setProperty("--wheel_list_stext", cPage?.listST ?? "#343434" );
    

    const data = [
      { option: '?' },
      { option: '?' },
      { option: '?' },
      { option: '?' },
      { option: '?' },
      { option: '?' },
      { option: '?' },
      { option: '?' },
      { option: '?' },
      { option: '?' },
      { option: '?' },
      { option: '?' },
    ]
    setAwards(data);
  }, [])

  async function validateSpeenCode(e: React.FormEvent<HTMLFormElement>){
    e.preventDefault();
    //ruota già in uso
    if(isInPending || isRotating || code[0].length !== 6) return
    setIsInPending(true)

    const codeResponse = await CustomServices.sendFetch<UseWheelCodeDto>(WheelCodeFetchUrl.UseWheelCode(code[0]) , {
        method: "GET",
    });
    code[1]("");
    if(codeResponse.error){
        if(codeResponse.error?.general) setPopUp({message:<p>{codeResponse.error.general}</p>});
        error[1](codeResponse.error);
        setIsInPending(false)
    }else{
      setPrize(codeResponse.data.award);
      setPrizeNumber(Math.floor(Math.random() * awards.length));
      setIsRotating(true);
    }  
  }

  function showPrize(){
    setIsRotating(false);
    setIsInPending(false);

    if(prize !== "riprova"){
      setPopUp({
        message:
          <div className={style["res-wheel"]}>
            <p className={style["res-wheel_title"]}>Hai vinto!</p>
            <p className={style["res-wheel_award"]}>{prize}</p>
            <p className={style["res-wheel_code"]}>Notifica lo sraff che il tuo codice vincente è: <span>{code[0]}</span></p>
          </div>, 
        buttons: [<button className="btn" onClick={() => setPopUp({message:null})}>Ricordo il codice</button>],
        wrapClass:style["res-wheel-box_btn"],
        stopPropagation: true
      })
    }else{
      setPopUp({
        message:
          <div className={style["res-wheel"]}>
            <p className={style["res-wheel_title"]}>Hai perso</p>
            <p className={style["res-wheel_award"]}>{prize}</p>
          </div>, 
        buttons: null
      })
    }
  }

  function showAwards(){
    setPopUp({
      message:
      <div className={style["table-award"]}>
      <p>PREMI IN PALIO</p>
      <ul>
        {wheelEvent.awards.map(award => {
          return <li key={award.name}>{award.name}</li>
        })}
      </ul>
    </div>, 
      buttons: null})
  }

  function showConditions(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>){
    e.preventDefault();

    setPopUp({
      message:
      <div className={style["box-conditions"]}>
        <p className={style["title-conditions"]}>Termini e Condizioni</p>
        <p>
          L'evento è l'emissione dei codici è gestita dall'attività in se,
          che si avvale del diritto di selezione dei partecipanti e 
          accettazione o meno della riscossione del premio
          indipendentemente dal codice vincente.
        </p>
        <p>Partecipando all'evento si accettano le condizioni sopraccitate.</p>

    </div>, 
      buttons: null})
  }

  return <div className="max900 m_center">

    {(isStaff || isMaster) && <>
        <WheelCodeGenerator idWheelEvent={wheelEvent._id} />
        <WheelCodeCheck  idWheelEvent={wheelEvent._id} wheelCodes={wheelEvent.codes}/>
      </>
    }
      <div className={style["box-wheel"]}>
        <button className={style["btn-showAward"]} type="button" title="mostra premi" onClick={showAwards}>Mostra premi</button>

        <h2 className={`${style["wheel-title"]} t_center`}>{wheelEvent.name}</h2>
        {awards.length && <Wheel
            mustStartSpinning={isRotating}
            prizeNumber={prizeNumber}
            data={awards}
            perpendicularText={true}
            onStopSpinning={() => showPrize()}
            spinDuration={cPage?.wheelSpeen ?? 1}
            backgroundColors={cPage?.wheelBg ?? ['#343434', '#cee5f2']}
            textColors={cPage?.wheelTC ?? ["#ffffff","#343434"]}
            fontFamily={cPage?.wheelFF ?? "Helvetica"}
            fontSize={cPage?.wheelFS ?? 22}

            outerBorderWidth={cPage?.wheelOBW ?? 3}
            outerBorderColor={cPage?.wheelOBC ?? "#181818"}
            radiusLineWidth={cPage?.wheelRLW ?? 2}
            radiusLineColor={cPage?.wheelRLC ?? "#181818"}

            borderShadow={{
              shadowWidth:cPage?.wheelBS?.width ?? 15, 
              shadowColor:cPage?.wheelBS?.color ?? "rgba(73, 73, 73, 0.06)"}}

            pointerProps={{
              src: Imgtriangle,
              style: {translate: "-50% -20%", top:10, left: "50%", filter: `invert(${cPage?.wheelImgWhite ? 1 : 0})`}
            }}
          />
        }
        <Form onSubmit={validateSpeenCode} addClass={style["form-code"]}>
          <Input addClass={style["input-code"]} 
            send={true} 
            name="codice" 
            label="inserisci il codice" 
            value={code} error={error} 
            minLength={6} maxLength={6}
            disabled={isRotating}
            />
        </Form>

        <a title="mostra termini e condizioni" className={style["showCondition"]} onClick={showConditions}>termini e condizioni</a>

      </div>
    </div>
  }

export async function loaderWheelEventPage(name: string){

  let wheelEventResp = await CustomServices.sendFetch<WheelEventModel>(WheelEventFetchUrl.GetWheelEvent(name.replaceAll("-"," ")), {
      method: "GET",
  })

  if(wheelEventResp.error){
    alert(wheelEventResp.error.general)
    window.location.href = "../"
  }
  return wheelEventResp.data;
}

export default WheelEvent;