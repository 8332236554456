import { ObjectId } from "mongoose";

export type CreateWheelCodeModel ={
    idWheelEvent: ObjectId,
}

export class UseWheelCodeDto {
    award: string 
}

export type DeleteWheelCodeModel ={
    idWheelEvent: ObjectId,
}

export class CheckWheelCode {
    _id: ObjectId;
    code: string;
    award: string;
}


export class WheelCodeFetchUrl{

    public static GenerateWheelCodeEvent() : string {
        return `wheelcode`;
    }

    public static UseWheelCode(codeId: string) : string {
        return `wheelcode/${codeId}/use`;
    }

    public static DeleteWheelCode(codeId: string, ) : string {
        return `wheelcode/${codeId}`;
    }
} 