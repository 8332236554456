import { ObjectId, Schema } from "mongoose";
import { FC, useContext, useEffect, useState } from "react";

import CustomServices from "./../../services/services";
import { PopUpContext } from "./../../store/context";
import { DeleteWheelCodeModel, WheelCodeFetchUrl } from "./../../model/WheelCode.model";

import GeneralStyle from "./../../App.module.css";
import style from "./WheelCodeCheck.module.css"
import { CheckWheelCode } from "./../../model/WheelCode.model";

const WheelCodeCheck: FC<{idWheelEvent: Schema.Types.ObjectId, wheelCodes: CheckWheelCode[]}> = ({idWheelEvent, wheelCodes: wheelCodeList}) => {

    const [wheelCode, setWheelCode] = useState<CheckWheelCode[]>(wheelCodeList);
    const [regFilterCode, setRegFilterCode] = useState(new RegExp(""));

    const [timeId, setTimeId] = useState<NodeJS.Timeout>();
    const [query, setQuery] = useState("");
    //const tentativi = useState<number>(1);
    const [popUp, setPopUp] = useContext(PopUpContext);

    useEffect(() => {
        if(timeId) clearTimeout(timeId);  

        const newTimeId = setTimeout(() => {
            setRegFilterCode(new RegExp(query))
          }, 500); // 500ms timeout
    
          setTimeId(newTimeId);
    }, [query])


    async function deleteCodeShowPopUp(e: React.MouseEvent<HTMLButtonElement>, idCode: ObjectId, code: string){
        e.preventDefault();
        
        setPopUp({
            message: <div>
                <p className={style["title-popUp"]}>Stai cancellando il codice</p>
                <p className={style["code-popUp"]}>{code}</p>
            </div>,
            buttons: [
                <button type="button" className="btn" onClick={e => deleteCode(e, idCode)}>conferma</button>, 
                <button type="button" className="btn btn_second" onClick={() => setPopUp({message: null})}>annulla</button>
            ],
            wrapClass: style["box_btn-popUp"]
            
        })
    }



    async function deleteCode(e: React.MouseEvent<HTMLButtonElement>, idCode: ObjectId){
        e.preventDefault();
        
        let body : DeleteWheelCodeModel = {
            idWheelEvent,
        }
        
        const wheelCodeResponse = await CustomServices.sendFetch<string>(WheelCodeFetchUrl.DeleteWheelCode(idCode+"") , {
            method: "DELETE",
            body: JSON.stringify(body)
        });

        if(wheelCodeResponse.error){
            //TODO: popup error o errore alrt 24/06/2024
            if(wheelCodeResponse.error?.general) setPopUp({message:<p>{wheelCodeResponse.error.general}</p>});
        }else{
            setWheelCode((listCode) => listCode.filter(code => code._id != idCode ));
            setPopUp({message: null});
        }        
    }

//AGGIUNGERE UN POPUP E AVVERTENZE DEL FUNZIONAMENTO DEL GIOCO
    return (<div className={style["code-box"]}>
        <div>
            <p>CODICI VINCENTI</p>
            <input type="search" maxLength={6} placeholder="Cerca codice" onChange={e => setQuery(e.target.value)}/>
        </div>

        <table className={style["table-code"]}>
            <tbody>
                {wheelCode.map((code) => {
                    if(regFilterCode.test(code.code)){
                        return <tr key={code._id+""}>
                            <td>{code.code}</td>
                            <td>{code.award}</td>
                            <td><button type="button" onClick={e => deleteCodeShowPopUp(e, code._id, code.code)}>X</button></td>
                        </tr>
                    }
                })}
            </tbody>
            {wheelCode.length === 0 && <tbody>
                <tr><td>Non ci sono codici vincenti</td></tr>    
            </tbody>}
        </table>
    </div>)
}

export default WheelCodeCheck;

